import React, { memo } from "react";
import { makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles(
  {
    marginBottom: {
      marginBottom: "20px",
    },
  },
  { index: 1 }
);

const LoadingSkeleton = () => {
  const { marginBottom } = useStyles();

  return (
    <>
      <Skeleton
        className={marginBottom}
        variant="rect"
        width="calc(99%)"
        height={30}
      />
      <Skeleton
        className={marginBottom}
        variant="rect"
        width="calc(99%)"
        height={30}
      />
      <Skeleton variant="rect" width="calc(99%)" height={30} />
    </>
  );
};

export default memo(LoadingSkeleton);
