import React, { useState } from "react";
import { t } from "ttag";
import MuiButton, {
  ButtonProps as MuiButtonProps,
} from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";
import { CircularProgress, Icons, Button } from "@src/components";
import { removeEquipment } from "@ats/graphql";
import { scaniaRedNegative, scaniaWhite } from "@src/constants/colors";

export type DegradedEquipmentProps = MuiButtonProps & {
  externalEquipmentReference: string;
  queueId: string;
};

const useStyles = makeStyles(
  {
    removeButton: {
      width: "93px",
      height: "73px",
      border: "none",
      backgroundColor: scaniaRedNegative,
      color: scaniaWhite,
      borderRadius: "4px",
      fontWeight: 400,
      "&:hover, &:focus": {
        backgroundColor: scaniaRedNegative,
      },
      "&:active": {
        backgroundColor: scaniaRedNegative,
        opacity: 0.5,
        color: scaniaWhite,
      },
      cursor: "pointer",
    },
  },
  { index: 1 }
);

const DegradedEquipment: React.FC<DegradedEquipmentProps> = ({
  externalEquipmentReference,
  queueId,
}) => {
  const { removeButton } = useStyles();
  const [activeRemoveButton, setActiveRemoveButton] = useState(true);
  const [removeEquipmentFailed, setRemoveEquipmentFailed] = useState(false);

  const sendRemoveEquipment = () => {
    removeEquipment(queueId, externalEquipmentReference)
      .then(() => {
        setRemoveEquipmentFailed(false);
      })
      .catch(() => {
        setRemoveEquipmentFailed(true);
      });
    setActiveRemoveButton(true);
  };

  const handleRemoveEquipment = () => {
    setActiveRemoveButton(false);
    sendRemoveEquipment();
  };

  return (
    <MuiButton
      className={removeButton}
      id={externalEquipmentReference}
      key={externalEquipmentReference}
      onClick={() => handleRemoveEquipment()}
      disabled={!activeRemoveButton}
    >
      {!activeRemoveButton && <CircularProgress size={24} />}
      {removeEquipmentFailed ? (
        <Button className={removeButton} endIcon={<Icons.Replay />}>
          {t`Retry`}
        </Button>
      ) : (
        <>{t`Remove`}</>
      )}
    </MuiButton>
  );
};

export default DegradedEquipment;
