import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import {
  scaniaBlack,
  scaniaRed,
  scaniaRed50,
  scaniaRedNegative,
  scaniaLightGray,
} from "@src/constants/colors";
import ErrorIcon from "@src/components/icons/Error";
import { UiState } from "@src/machines/ui/ui.machine";
import {
  Box,
  Lab,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Grid,
  Typography,
} from "@src/components";
import DegradedEquipment from "@src/containers/Vehicles/DegradedEquipment.component";
import LoadingSkeleton from "@src/components/LoadingSkeleton/LoadingSkeleton";
import { t } from "ttag";
import clsx from "clsx";
import {
  dequeueEquipmentPromise,
  useMergedQueueState,
  useMergedPaddockData,
  clearPaddockPromise,
} from "@ats/graphql";

type DumpingZoneProps = {
  uiState: UiState;
  queueId: string;
  paddockId: string;
  siteId: string;
  zoneType: string;
};

export enum IMergedQueueStateStatus {
  NOMINAL = "Nominal",
  DEGRADED = "Degraded",
}

const useStyles = makeStyles(
  {
    root: {
      color: scaniaBlack,
      flexDirection: "column",
      maxWidth: "calc(100% - 20%)",
      height: "calc(100% - 45%)",
      minWidth: "360px",
      margin: "auto",
      overflow: "auto",
    },
    header: {
      height: "68px",
      fontSize: "14px",
      lineHeight: "16px",
      display: "flex",
      alignItems: "center",
      paddingLeft: "13px",
      fontWeight: 800,
      color: scaniaBlack,
      letterSpacing: "-0.01rem",
      textTransform: "capitalize",
    },
    listButton: {
      borderRadius: "0px",
      left: "2px",
      width: "calc(99%)",
      height: "72px",
      justifyContent: "flex-start",
      color: scaniaBlack,
      fontSize: "16px",
      marginBottom: "6px",
      boxShadow:
        "0px 3px 3px rgba(0, 0, 0, 0.15), 0px -1px 1px rgba(0, 0, 0, 0.1)",
      cursor: "auto",
    },
    paddockContainer: {
      marginTop: "32px",
      flexDirection: "column",
      maxWidth: "calc(100% - 20%)",
      minWidth: "360px",
      margin: "auto",
      background: "#FFFFFF",
      alignItems: "flex-start",
      color: scaniaBlack,
      boxShadow:
        "0px 3px 3px rgba(0, 0, 0, 0.15), 0px -1px 1px rgba(0, 0, 0, 0.1)",
    },
    paddockStatusHeader: {
      fontWeight: 700,
      paddingLeft: "16px",
      paddingTop: "27px",
      paddingBottom: "27px",
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "-0.02em",
    },
    paddockStatus: {
      color: "#1DAB8B",
      fontWeight: 700,
      paddingRight: "16px",
      paddingLeft: "35%",
      width: "32px",
      height: "16px",
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "-0.02em",
    },
    paddockStatusFull: {
      color: scaniaRed,
      fontWeight: 700,
      paddingRight: "16px",
      paddingLeft: "35%",
      width: "32px",
      height: "16px",
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "-0.02em",
    },
    callNextButton: {
      borderRadius: "10px",
      height: "56px",
      width: "360px",
      border: "1px solid #000000",
      color: scaniaBlack,
      fontSize: "16px",
      marginBottom: "6px",
      position: "fixed",
      fontWeight: 400,
      bottom: "4px",
    },
    callNextAlert: {
      borderRadius: "10px",
      height: "56px",
      width: "360px",
      fontSize: "16px",
      position: "fixed",
      fontWeight: 400,
      bottom: "65px",
    },
    label: {
      paddingLeft: "13px",
      fontWeight: 800,
      letterSpacing: "-0.01rem",
      color: scaniaLightGray,
    },
    paddockClear: {
      borderRadius: "10px",
      height: "56px",
      width: "calc(95%)",
      border: "1px solid #000000",
      color: scaniaBlack,
      fontSize: "16px",
      marginBottom: "6px",
      fontWeight: 400,
      bottom: "10px",
    },
    paddockClearAlert: {
      height: "56px",
      width: "calc(95%)",
      color: scaniaBlack,
      fontSize: "8px",
      marginBottom: "16px",
      fontWeight: 400,
      bottom: "10px",
    },
    callNext: {
      display: "flex",
      alignItems: "center",
      color: scaniaBlack,
      flexDirection: "column",
      textAlign: "center",
      width: "inherit",
    },
    avatar: {
      borderRadius: "50%",
      width: "34px",
      height: "34px",
      padding: "10px",
      background: "#5DA7A9",
      textAlign: "center",
      color: "#000000",
      lineHeight: "16px",
    },
    borderTop: {
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      marginBottom: "15px",
    },
    primaryLabel: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      "@media only screen and (max-width: 420px)": {
        width: "215px",
        overflowWrap: "break-word",
      },
    },
    secondaryLabel: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "16px",
      "@media only screen and (max-width: 420px)": {
        width: "215px",
        overflowWrap: "break-word",
      },
    },
    toastContainer: {
      width: "calc(99.5%)",
      marginLeft: "2px",
      background: scaniaRed50,
      height: "88px",
      marginBottom: "16px",
      borderLeft: `4px solid ${scaniaRedNegative}`,
      borderRadius: "4px",
      color: "rgba(13, 15, 19, 0.87)",
      display: "flex",
    },
    toastChild: {
      paddingTop: "15px",
    },
    toastBody: {
      fontWeight: 400,
    },
    toastHeader: {
      fontWeight: 700,
    },
    toastIcon: {
      padding: "17px 17px 0px 17px",
    },
    degradedButton: {
      backgroundColor: scaniaRed50,
      borderLeft: `4px solid ${scaniaRedNegative}`,
      "&:hover, &:focus, &:active": {
        backgroundColor: scaniaRed50,
      },
    },
    secondaryAction: {
      right: "0px",
    },
  },
  { index: 1 }
);

const DumpingZone = ({ queueId, paddockId, siteId }: DumpingZoneProps) => {
  const {
    avatar,
    root,
    header,
    listButton,
    callNext,
    callNextButton,
    callNextAlert,
    paddockContainer,
    paddockStatusHeader,
    paddockStatus,
    paddockStatusFull,
    paddockClear,
    label,
    paddockClearAlert,
    borderTop,
    primaryLabel,
    secondaryLabel,
    toastContainer,
    toastChild,
    toastBody,
    toastHeader,
    toastIcon,
    degradedButton,
    secondaryAction,
  } = useStyles();

  const [activeCallNext, setActiveCallNext] = useState(true);
  const [callNextFailed, setCallNextFailed] = useState(false);
  const [clearPaddockFailed, setClearPaddockFailed] = useState(false);
  const [activePaddockClear, setActivePaddockClear] = useState(true);
  const [paddockFull, setPaddockFull] = useState(false);

  const [queueState] = useMergedQueueState({
    areaId: siteId,
    queueId,
  });

  const [paddockState] = useMergedPaddockData({
    areaId: siteId,
    paddockId,
  });

  useEffect(() => {
    if (
      paddockState &&
      paddockState.length > 0 &&
      Number(paddockState[0].filledSpots) +
        Number(paddockState[0].failedSpots) ===
        Number(paddockState[0].totalSpots)
    )
      setPaddockFull(true);
    else setPaddockFull(false);
  }, [paddockState]);

  const sendClearPaddock = () => {
    clearPaddockPromise({
      areaId: siteId,
      paddockId,
    })
      .then(() => {
        setActivePaddockClear(true);
        setClearPaddockFailed(false);
      })
      .catch(() => {
        setActivePaddockClear(true);
        setClearPaddockFailed(true);
      });
  };

  const sendCallNextInQueue = () => {
    dequeueEquipmentPromise(queueId)
      .then(() => {
        setActiveCallNext(true);
        setCallNextFailed(false);
      })
      .catch(() => {
        setActiveCallNext(true);
        setCallNextFailed(true);
      });
  };
  const handleClick = () => {
    setActiveCallNext(false);
    sendCallNextInQueue();
  };

  const handleClear = () => {
    setActivePaddockClear(false);
    sendClearPaddock();
  };

  const paddockStatusList = [
    "Idle",
    "Awaiting dispatch",
    "Approaching",
    "Unloading",
    "Leaving",
  ];

  return (
    <>
      {(!paddockState || (!!paddockState && paddockState.length === 0)) && (
        <div className={paddockContainer}>
          <Grid container className={clsx(paddockStatusHeader)}>
            <Grid item xs={6}>
              Paddock Status
            </Grid>

            <Grid item xs={6} className={paddockStatus}>
              No data
            </Grid>
          </Grid>
        </div>
      )}
      {!!paddockState && paddockState.length > 0 && (
        <div className={paddockContainer}>
          <Grid container className={clsx(paddockStatusHeader)}>
            <Grid item xs={6}>
              Paddock Status
            </Grid>
            {paddockFull && (
              <Grid item xs={6} className={paddockStatusFull}>
                FULL
              </Grid>
            )}
            {!paddockFull && (
              <Grid item xs={6} className={paddockStatus}>
                {paddockState[0].filledSpots + paddockState[0].failedSpots}/
                {paddockState[0].totalSpots}
              </Grid>
            )}
          </Grid>

          {paddockState[0].equipment.length > 0 && !paddockFull && (
            <>
              {paddockState[0]?.equipment.map(
                ({ displayName, equipmentStatus }) =>
                  equipmentStatus &&
                  paddockStatusList.includes(equipmentStatus) ? (
                    <ListItem button className={borderTop}>
                      <ListItemAvatar>
                        <Avatar>
                          <div className={avatar} />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography className={primaryLabel}>
                            {displayName}
                          </Typography>
                        }
                        secondary={
                          <Typography className={secondaryLabel}>
                            {equipmentStatus}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ) : null
              )}
            </>
          )}

          <div className={callNext}>
            {clearPaddockFailed && (
              <Box bottom="100%" width={1} className={paddockClearAlert}>
                <Lab.Alert severity="error">
                  <Lab.AlertTitle>{t`Unable to clear paddock`}</Lab.AlertTitle>
                </Lab.Alert>
              </Box>
            )}
            <Button
              variant="outlined"
              className={paddockClear}
              onClick={handleClear}
              loading={!activePaddockClear}
            >
              {t`Clear paddock`}
            </Button>
          </div>
        </div>
      )}
      {queueState === null && (
        <>
          <List className={root}>
            <div className={header}>Queue</div>
            <LoadingSkeleton />
          </List>
        </>
      )}
      {!!queueState && queueState?.length === 0 && (
        <>
          <List className={root}>
            <div className={header}>Queue</div>
            No queue data
          </List>
        </>
      )}
      {!!queueState && queueState.length > 0 && (
        <>
          <List className={root}>
            <div className={header}>{queueState[0]?.displayName}</div>

            {queueState[0]?.status === IMergedQueueStateStatus.DEGRADED && (
              <div className={toastContainer}>
                <div className={clsx(toastIcon, toastChild)}>
                  <ErrorIcon />
                </div>

                <div className={toastChild}>
                  <div>
                    <span className={toastHeader}>Queue degraded!</span>
                  </div>

                  <div>
                    <span className={toastBody}>
                      Remove highlighted vehicle to restore queue
                    </span>
                  </div>
                </div>
              </div>
            )}
            {queueState[0]?.equipment.length === 0 && (
              <div className={label}>No vehicle in the queue</div>
            )}
            {queueState[0]?.equipment.map(
              (
                {
                  displayName,
                  externalEquipmentReference,
                  pos,
                  missionLabel,
                  type,
                },
                index
              ) => (
                <ListItem
                  button
                  key={index}
                  className={
                    type === "EqMoveFailed"
                      ? clsx(listButton, degradedButton)
                      : listButton
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <div className={avatar}>{pos}</div>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography className={primaryLabel}>
                        {displayName}
                      </Typography>
                    }
                    secondary={
                      <Typography className={secondaryLabel}>
                        {missionLabel || ""}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction className={secondaryAction}>
                    {type === "EqMoveFailed" && (
                      <DegradedEquipment
                        externalEquipmentReference={externalEquipmentReference}
                        queueId={queueId}
                      />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              )
            )}
          </List>
          <div className={callNext}>
            {callNextFailed && (
              <Box bottom="100%" width={1} className={callNextAlert}>
                <Lab.Alert severity="error">
                  <Lab.AlertTitle>{t`Unable to call next in queue`}</Lab.AlertTitle>
                </Lab.Alert>
              </Box>
            )}
            <Button
              variant="outlined"
              className={callNextButton}
              onClick={handleClick}
              disabled={
                !activeCallNext ||
                paddockFull ||
                !queueState ||
                (queueState && queueState[0].equipment.length < 1)
              }
              loading={!activeCallNext}
            >
              {t`Call next in queue`}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default DumpingZone;
