import React from "react";
import MuiTextField, {
  TextFieldProps,
} from "@material-ui/core/TextField/TextField";

const TextField = ({ InputLabelProps, ...rest }: TextFieldProps) => (
  <MuiTextField
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    InputLabelProps={{
      ...InputLabelProps,
      ...(rest.variant === "outlined" && { shrink: false }),
    }}
  />
);

export default TextField;
