import React from "react";
import { useUiService, useUser } from "@src/hooks";
import { Header } from "@src/containers";
import DumpingZone from "./DumpingZone.container";
import LoadingZone from "./LoadingZone.container";

export interface Equipment {
  readonly id: string;
  readonly displayName: string;
  readonly position: number;
}

const QueueView = () => {
  const [uiState, uiSend] = useUiService();
  const zoneType = uiState.context?.zoneType || "";
  const siteId = uiState.context?.siteId || "";
  const paddockId = uiState.context?.paddockId || "";
  const queueId = uiState.context?.queueId || "";
  const { role } = useUser();
  const handleBack = () => uiSend({ type: "VIEW_ZONES" });

  return (
    <>
      {role === "dumpingOperator" && (
        <>
          <Header
            uiState={uiState}
            uiSend={uiSend}
            text={uiState.context.zoneName}
            handleBack={handleBack}
            sites={uiState.context.sites ? uiState.context.sites : undefined}
          />
          <DumpingZone
            uiState={uiState}
            queueId={queueId}
            zoneType={zoneType}
            paddockId={paddockId}
            siteId={siteId}
          />
        </>
      )}
      {role === "loadingOperator" && (
        <>
          <Header
            uiState={uiState}
            uiSend={uiSend}
            text={uiState.context.zoneName}
            handleBack={handleBack}
            sites={uiState.context.sites ? uiState.context.sites : undefined}
          />
          <LoadingZone
            uiState={uiState}
            queueId={queueId}
            zoneType={zoneType}
            uiSend={uiSend}
            paddockId={paddockId}
            siteId={siteId}
          />
        </>
      )}
    </>
  );
};

export default QueueView;
