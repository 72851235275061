import React from "react";
import { TextFieldProps } from "@material-ui/core/TextField/TextField";
import TextField from "../TextField/TextField.component";

const Select = (props: TextFieldProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <TextField {...props} select />
);

export default Select;
