import React from "react";
import { makeStyles } from "@material-ui/core";
import { scaniaBlack } from "@src/constants/colors";
import { UiState, UiEvent } from "@src/machines/ui/ui.machine";
import { SiteRelation_queueRelations as queueRelation } from "@ats/graphql";
import { UserRole } from "@src/casl/ability";
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
} from "@src/components";
import { ReactComponent as ForwardArrow } from "@src/assets/icons/forward-arrow.svg";
import { ReactComponent as LoadingZone } from "@src/assets/icons/loading-zone.svg";
import { ReactComponent as DumpingZone } from "@src/assets/icons/dumping-zone.svg";

type ZoneProps = {
  zones: ReadonlyArray<queueRelation> | null;
  uiState: UiState;
  uiSend: (event: UiEvent) => void;
  role: UserRole;
  loading: boolean;
};

const useStyles = makeStyles(
  {
    root: {
      color: scaniaBlack,
      flexDirection: "column",
      maxWidth: "calc(100% - 20%)",
      height: "calc(100vh)",
      minWidth: "360px",
      margin: "auto",
    },
    button: {
      borderRadius: "0px",
      height: "72px",
      width: "inherit",
      justifyContent: "flex-start",
      color: scaniaBlack,
      fontSize: "16px",
      marginBottom: "6px",
      boxShadow:
        "0px 3px 3px rgba(0, 0, 0, 0.15), 0px -1px 1px rgba(0, 0, 0, 0.1)",
    },
    header: {
      height: "68px",
      fontSize: "14px",
      lineHeight: "16px",
      display: "flex",
      alignItems: "center",
      paddingLeft: "24px",
      fontWeight: 700,
      color: scaniaBlack,
      letterSpacing: "-0.01rem",
    },
    noData: {
      paddingTop: "24px",
      paddingLeft: "24px",
    },
  },
  { index: 1 }
);

const Zones = ({ zones, uiSend, role, loading }: ZoneProps) => {
  const { root, header, button, noData } = useStyles();
  const selectedRole =
    role === "dumpingOperator" ? "DUMPING_ZONE" : "LOADING_ZONE";
  const filteredZones = zones?.filter((zone) => zone?.type === selectedRole);
  const zonesDataCheck = filteredZones && filteredZones.length < 1;

  return (
    <>
      <List className={root}>
        <div className={header}>Select a zone</div>
        {!loading && zonesDataCheck && (
          <div className={noData}> No zones to select</div>
        )}
        {filteredZones &&
          filteredZones.map(({ displayName, id, type, queueId, paddockId }) => (
            <ListItem
              button
              key={id}
              className={button}
              onClick={() =>
                uiSend({
                  type: "SELECT_ZONE",
                  queueId,
                  paddockId,
                  zoneName: displayName,
                  zoneType: type,
                })
              }
            >
              <ListItemAvatar>
                <Avatar>
                  {type === "LOADING_ZONE" && <LoadingZone />}

                  {type === "DUMPING_ZONE" && <DumpingZone />}
                </Avatar>
              </ListItemAvatar>
              <ListItemText disableTypography>{displayName}</ListItemText>
              <ListItemSecondaryAction>
                <ForwardArrow />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
      </List>
    </>
  );
};

export default Zones;
