import React from "react";
import {
  VehicleEvent,
  VehicleState,
} from "@src/machines/vehicle/vehicle.machine";
import { t } from "ttag";
import VehicleActionButtonContainer from "../../VehicleActionButton.container";

interface ReleaseVehicleStates {
  releasing: boolean;
}

interface ReleaseVehicleActions {
  releaseVehicle: () => void;
  done: () => void;
}

export const getReleaseVehicleStates = (
  state: VehicleState
): ReleaseVehicleStates => {
  return {
    releasing: state.matches(
      "connection.online.interaction.releaseVehicle.releasing"
    ),
  };
};

export const getReleaseVehicleActions = (
  send: (event: VehicleEvent) => void,
  done: () => void
): ReleaseVehicleActions => ({
  releaseVehicle: () => send({ type: "SELECT_RELEASE_VEHICLE" }),
  done: () => {
    done();
  },
});

type ReleaseVehicleProps = {
  states: ReleaseVehicleStates;
  actions: ReleaseVehicleActions;
  eStopDegradationWithHealedFault: boolean;
};

const ReleaseVehicle: React.FC<ReleaseVehicleProps> = ({
  states,
  actions,
  eStopDegradationWithHealedFault,
}) => {
  return (
    <VehicleActionButtonContainer
      action="RELEASE_VEHICLE"
      disabled={!eStopDegradationWithHealedFault}
      onClick={actions.releaseVehicle}
      testId="release-button"
      labelTitle={t`Release vehicle`}
      buttonText={t`Release`}
      loading={states.releasing}
    />
  );
};

export default ReleaseVehicle;
