import React, { useState, useEffect, useCallback } from "react";
import { sendRelease, saveOperatorLastDequeue } from "@ats/graphql";
import { useUiService } from "@src/hooks";
import { Lab, SwitchSlider, Box } from "@src/components";
import {
  VehicleEvent,
  VehicleState,
} from "@src/machines/vehicle/vehicle.machine";
import { isEqual } from "lodash";
import { t } from "ttag";

type FinishLoadingStates = {
  sendCommand: boolean;
  pending: boolean;
  success: boolean;
  failed: boolean;
};

type FinishLoadingActions = {
  releasePersonalStandDown: () => void;
};

export const getFinishLoadingStates = (
  state: VehicleState
): FinishLoadingStates => {
  return {
    sendCommand: state.matches(
      "connection.online.command.releasePersonalStandDown.sendCommand"
    ),
    pending: state.matches(
      "connection.online.command.releasePersonalStandDown.pending"
    ),
    success: state.matches(
      "connection.online.command.releasePersonalStandDown.success"
    ),
    failed: state.matches(
      "connection.online.command.releasePersonalStandDown.failed"
    ),
  };
};

export const getFinishLoadingActions = (
  send: (event: VehicleEvent) => void
): FinishLoadingActions => {
  return {
    releasePersonalStandDown: () =>
      send({ type: "COMMAND_RELEASE_PERSONAL_STAND_DOWN" }),
  };
};

const getFinishLoadingStatus = ({
  sendCommand,
  pending,
  success,
}: FinishLoadingStates) => {
  if (sendCommand) {
    return t`Sending`;
  }

  if (pending) {
    return t`Wait`;
  }

  if (success) {
    return t`Success`;
  }

  return "";
};

type FinishLoadingProps = {
  states: FinishLoadingStates;
  actions: FinishLoadingActions;
  externalEquipmentReference: string;
  startLoadingFlag: boolean;
};

const FinishLoading: React.FC<FinishLoadingProps> = React.memo(
  ({ states, actions, externalEquipmentReference, startLoadingFlag }) => {
    const [uiState] = useUiService();
    const [checked, setChecked] = useState(false);
    const [error, setError] = useState<boolean>(false);
    const siteId = uiState.context?.siteId || null;
    const queueId = uiState.context?.queueId || "";
    const { failed } = states;
    const callback = useCallback(() => {
      setChecked(true);
      actions.releasePersonalStandDown();

      // If the startLoadingFlag is true, then the externalEquipmentReference entry is deleted from the dynamodb table
      if (startLoadingFlag) {
        saveOperatorLastDequeue({
          areaId: siteId,
          externalEquipmentReference,
          queueId,
          delete: true,
        });
      }

      sendRelease(externalEquipmentReference).catch(() => {
        setError(true);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalEquipmentReference]);

    useEffect(() => {
      if (failed) {
        setChecked(false);
      }
    }, [actions, failed]);

    const handleChecked = callback;

    return (
      <Box position="relative">
        {(failed || error) && (
          <Box bottom="100%" width={1}>
            <Lab.Alert severity="error">
              <Lab.AlertTitle>{t`Unable to Finish Loading`}</Lab.AlertTitle>
            </Lab.Alert>
          </Box>
        )}
        <SwitchSlider
          leftLabel={t`Finish Loading`}
          rightLabel={getFinishLoadingStatus(states)}
          checked={checked}
          onChecked={handleChecked}
          direction="rtl"
        />
      </Box>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps.states, nextProps.states)
);

export default FinishLoading;
