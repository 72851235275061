import React, { useState, useEffect, createContext } from "react";
import { useMachine } from "@xstate/react";
import { Observable, combineLatest } from "rxjs";
import {
  vehiclesMachine,
  VehiclesService,
  VehiclesContext as VehiclesMachineContext,
  VehiclesEvent,
  VehiclesTypeState,
} from "@src/machines/vehicles/vehicles.machine";
import { config } from "@src/config";
import { InvokeCreator, AnyEventObject, assign, spawn } from "xstate";
import createVehicleMachine, {
  VehicleContext as VehicleMachineContext,
} from "@src/machines/vehicle/vehicle.machine";
import { getActionLists } from "@src/machines/vehicle/states/actionLists.states";
import { sendInVehicleAndPersonalStandDown } from "@src/machines/vehicle/states/inVehicleAndPersonalStandDown.states";
import { sendStandDownPersonal } from "@src/machines/vehicle/states/personalStandDown.states";
import { sendPersonalOwnership } from "@src/machines/vehicle/states/personalOwnership.states";
import { sendReleasePersonalOwnership } from "@src/machines/vehicle/states/releasePersonalOwnership.states";
import { sendReleaseVehicle } from "@src/machines/vehicle/states/releaseVehicle.states";
import { sendReleasePersonal } from "@src/machines/vehicle/states/releasePersonalStandDown.states";
import { sendReleaseToContinue } from "@src/machines/vehicle/states/releaseToContinue.states";
import {
  sendIdentify,
  subscribeToControlState,
} from "@src/machines/vehicle/states/identify.states";
import {
  sendExecuteActionList,
  subscribeToMissionState,
} from "@src/machines/vehicle/states/dispatch.states";
import {
  // getEquipmentStatusesPromise,
  getEquipmentPromise,
  EquipmentStatus,
  getEquipmentStatuses,
  getConnectivityStatus,
} from "@ats/graphql";
import moment from "moment";
import {
  currentAccessToken,
  selectedSiteId,
  lastConnected,
} from "@src/components/Model/observables";
import { isEmpty, omit } from "lodash";

import { VehiclesQuery_allEquipment as Vehicle } from "@src/graphql/schemaTypes";

export const VehiclesContext = createContext<{
  vehicleService: VehiclesService;
}>(null as any);

export interface IProps {
  service: VehiclesService;
}

interface IReleaseConditions {
  type?: string | null;
  owner?: string | null;
  staff?: string;
  __typename: string;
}

interface IExtendedEquipmentStatus extends EquipmentStatus {
  status: string | undefined;
  reason: string | null | undefined;
}
class MapEquipmentStatus {
  public mode: string | null = "";

  public owner: string | null = "";

  public staff?: any;

  public releaseConditions: Array<IReleaseConditions> = [];

  public operationalState: string | null = "";

  public __typename!: string;

  public isError(): boolean {
    return (
      this.operationalState === "OPERATIONAL_STATE_INVALID" ||
      this.releaseConditions.findIndex(
        (releaseCondition) => releaseCondition.type === "TYPE_INVALID"
      ) > -1
    );
  }

  public canRelease(): boolean {
    return (
      this.releaseConditions.findIndex(
        (releaseCondition) => releaseCondition.type === "TYPE_IMPERSONAL"
      ) > -1
    );
  }

  public canClearMission(): boolean {
    return this.operationalState === "OPERATIONAL_STATE_STAND_DOWN";
  }

  public canDrive(): boolean {
    return (
      this.isControllableMode() &&
      (this.operationalState === "OPERATIONAL_STATE_NORMAL" ||
        this.operationalState === "OPERATIONAL_STATE_IDLE" ||
        this.operationalState === "OPERATIONAL_STATE_IDLE_RESTRICTED") &&
      this.releaseConditions.length === 0
    );
  }

  public canStandDown(): boolean {
    return this.isControllableMode() && this.releaseConditions.length === 0;
  }

  public canStandDownPersonal(): boolean {
    return (
      this.isControllableMode() &&
      this.releaseConditions.findIndex(
        (releaseCondition) =>
          releaseCondition.type === "TYPE_PERSONAL" &&
          releaseCondition.owner === (window as any).external_staff_reference
      ) === -1
    );
  }

  public canReleasePersonal(): boolean {
    return (
      this.releaseConditions.findIndex(
        (releaseCondition) =>
          releaseCondition.type === "TYPE_PERSONAL" &&
          releaseCondition.owner === (window as any).external_staff_reference
      ) > -1
    );
  }

  public isControllableMode(): boolean {
    return (
      this.mode === "MODE_AUTONOMOUS" ||
      this.mode === "MODE_SHADOW" ||
      this.mode === "MODE_UNMANNED"
    );
  }

  public safeState(): string {
    if (this.inEquipmentStandDown() && this.personalStandDownByMe()) {
      return "SAFE";
    }

    if (this.inEquipmentStandDown()) {
      return "CAUTION";
    }

    if (this.personalStandDownByMe()) {
      return "CAUTION";
    }

    if (this.personalStandDown()) {
      return "CAUTION";
    }

    return "NOT_SAFE";
  }

  public canApproach(): boolean {
    return (
      !this.personalStandDown() &&
      !this.inEquipmentStandDown() &&
      // This should shange for personal stan down for me
      (this.mode === "MODE_AUTONOMOUS" || this.mode === "MODE_UNMANNED")
    );
  }

  public canLeave(): boolean {
    return (
      (!this.personalStandDown() &&
        this.inEquipmentStandDown() &&
        this.mode === "MODE_AUTONOMOUS") ||
      this.mode === "MODE_UNMANNED"
    );
  }

  public personalStandDownByMe(): boolean {
    return !!this.releaseConditions.find(
      (r) =>
        r.type === "TYPE_PERSONAL" &&
        r.owner === (window as any).external_staff_reference
    );
  }

  private personalStandDown(): boolean {
    return !!this.releaseConditions.find((r) => r.type === "TYPE_PERSONAL");
  }

  private inEquipmentStandDown(): boolean {
    return !!this.releaseConditions.find((r) => r.type === "TYPE_IN_EQUIPMENT");
  }
}

const status = (current: IExtendedEquipmentStatus) => {
  const mapEquipmentStatus = new MapEquipmentStatus();
  const releaseConditions =
    current.releaseConditions === null ||
    current.releaseConditions === undefined
      ? []
      : current.releaseConditions;
  const aReleaseConditions = releaseConditions.map((item) => ({
    __typename: "ReleaseCondition",
    staff: "",
    owner: item?.owner,
    type: item?.type,
  }));
  mapEquipmentStatus.mode = current.mode;
  mapEquipmentStatus.owner = current.owner;
  mapEquipmentStatus.operationalState = current.operationalState;
  mapEquipmentStatus.releaseConditions = aReleaseConditions;

  return {
    externalEquipmentReference: current.externalEquipmentReference,
    timestamp: current.timestamp,
    mode: current.mode,
    mapVersion: current.mapVersion,
    speed: current.speed,
    operationalState: current.operationalState,
    owner: current.owner,
    dispatcher: current.dispatcher,
    staff: null,
    releaseConditions: aReleaseConditions,
    position: current.position,
    personalStandDownByMe: mapEquipmentStatus.personalStandDownByMe(),
    safeState: mapEquipmentStatus.safeState(),
    canApproach: mapEquipmentStatus.canApproach(),
    canLeave: mapEquipmentStatus.canLeave(),
    status: current.status || null,
    reason: current.reason || null,
    __typename: "EquipmentStatus",
  };
};

// CAV-66818 Cache all vehicles to be able to easily match when we set up the equipmentStatus subscription
const allVehicles: Omit<Vehicle, "__typename">[] = [];

// Function to get all vehicles for the whole customer
// equipmentStatus will be added as soon as the subscription is setup *per area*
// Changed as part of CAV-66818
const getVehicles = () => {
  while (allVehicles.length > 0) allVehicles.pop(); // Clear the local cache in case we start support re-fetch of vehicles

  return new Promise((resolve) => {
    getEquipmentPromise().then((result) => {
      const equipmentList = result;
      // Create an array of the equipment that match the state machine
      const data = equipmentList.map((equipment) => {
        if (!equipment) return null;
        return {
          id: equipment.externalEquipmentReference,
          externalEquipmentReference: equipment.externalEquipmentReference,
          displayName: equipment.displayName,
          areaId: equipment.areaId,
          status: undefined, // Initial value, spinner will be shown for each vehicle listed
          __typename: "Equipment",
        };
      });

      // CAV-66818 Review - Store equipment locally. Can we get the vehicles from the stored context in some way instead?
      if (data && data.length > 0)
        data.forEach((v) => {
          if (v) allVehicles.push(omit(v, "__typename"));
        });

      resolve({ data: { allEquipment: data } });
    });
  });
};

const VehiclesProvider: React.FC = ({ children }) => {
  const [mounted, setMounted] = useState<Boolean>(false);
  const sendVehiclesQuery = () => getVehicles();

  const equipmentStatusErrorTimeout = 10000; // will show red icon for vehicles if we havent' received equipmentStatus within 10 seconds

  const subscribeToVehicleStatus = () =>
    new Observable((subscriber) => {
      let equipmentStatusesSub: any;
      let reconnectInterval: ReturnType<typeof setInterval> | undefined;
      let reconnectAttempt = 0;
      // Listen to changes to area OR access token, we need to re-subscribe
      const siteChangeSub = combineLatest([
        selectedSiteId,
        currentAccessToken,
      ]).subscribe(([siteId, accessToken]) => {
        if (equipmentStatusesSub) {
          equipmentStatusesSub.unsubscribe(); // Unsubscribe from previous equipmentStatuses
          equipmentStatusesSub = undefined;
          // eslint-disable-next-line no-console
          console.debug(
            "Unsubscribed from getEquipmentStatuses() due to site or token change"
          );
        }
        if (siteId && accessToken && !isEmpty(accessToken)) {
          //
          // Loop through vehicle that are part of this area and has a status of undefined and setup a timeout
          // to be able to set error mode for status (null) if we don't get the equipmentStatus withing the given time
          const missingStatusTimeouts: [string, number][] = [];
          if (allVehicles) {
            allVehicles.forEach((vehicle) => {
              if (vehicle.areaId === siteId && vehicle.status === undefined) {
                const timeout = setTimeout(() => {
                  subscriber.next({
                    type: "VEHICLE_STATUS_UPDATE",
                    ...vehicle,
                    status: null,
                  });
                }, equipmentStatusErrorTimeout);
                missingStatusTimeouts.push([
                  vehicle.externalEquipmentReference,
                  timeout,
                ]);
              }
            });
          }

          equipmentStatusesSub = combineLatest(
            getEquipmentStatuses({
              areaId: siteId,
            }),
            getConnectivityStatus({
              areaId: siteId,
            })
          ).subscribe(
            ([eqState, connState]) => {
              // Connection established if valid, clear interval
              if (reconnectInterval) {
                clearInterval(reconnectInterval);
                reconnectInterval = undefined;
              }
              reconnectAttempt = 0;

              const equipmentState: IExtendedEquipmentStatus[] = eqState.map(
                (itm) =>
                  ({
                    ...connState.find(
                      (item) =>
                        item.externalEquipmentReference ===
                          itm.externalEquipmentReference && item
                    ),
                    ...itm,
                  } as IExtendedEquipmentStatus)
              );

              // We *might* get zombie equipmentStatuses for old vehicles, and those we ignore with the filter below
              equipmentState
                .filter(
                  (item) =>
                    allVehicles.findIndex(
                      (veh) =>
                        veh.externalEquipmentReference ===
                        item.externalEquipmentReference
                    ) > -1
                )
                .forEach((item) => {
                  subscriber.next({
                    type: "VEHICLE_STATUS_UPDATE",
                    ...status(item),
                  });

                  // Check if we need to clear the timeout we did setup to detect errors for equipmentStatus not received
                  const idx = missingStatusTimeouts.findIndex(
                    (i) => i[0] === item.externalEquipmentReference
                  );
                  if (idx > -1) {
                    const [[, timeout]] = missingStatusTimeouts.splice(idx, 1);
                    clearTimeout(timeout);
                  }
                });

              // Keep track globally of when we last received data (rounded to every 2 seconds)
              const m = moment();
              const roundedTime = m
                .subtract(m.seconds() % 2, "seconds")
                .startOf("second")
                .toISOString();
              if (roundedTime !== lastConnected.getValue())
                lastConnected.next(roundedTime);
            },
            () => {
              // eslint-disable-next-line no-console
              console.warn(
                "AppSync connection for getEquipmentStatuses() lost, will try to reconnect..."
              );
              // if (equipmentStatusesSub) equipmentStatusesSub.unsubscribe();
              equipmentStatusesSub = undefined;
              lastConnected.next(null);

              // Setup an interval to continue trying
              reconnectInterval = setInterval(() => {
                if (equipmentStatusesSub === undefined) {
                  reconnectAttempt += 1;
                  if (navigator.onLine) {
                    // eslint-disable-next-line no-console
                    console.debug(
                      "Trigger getEquipmentStatuses() reconnect attempt ",
                      reconnectAttempt
                    );
                    currentAccessToken.next(currentAccessToken.getValue());
                  }
                }
              }, 2000);
            }
          );
          // eslint-disable-next-line no-console
          console.debug(
            "Created subscription for getEquipmentStatuses() for area ",
            siteId
          );
        } else if (!equipmentStatusesSub && siteId) {
          // eslint-disable-next-line no-console
          console.warn(
            "getEquipmentStatuses() not setup. Token set: ",
            accessToken && !isEmpty(accessToken)
          );
        }
      });
      return () => {
        // If this observable is unsubscribed from we need to stop the equipmentStatuses sub
        if (equipmentStatusesSub) {
          equipmentStatusesSub.unsubscribe();
        }
        // Unsubscribe from site change subscriptions
        siteChangeSub.unsubscribe();
      };
    });

  const [, , service] = useMachine<
    VehiclesMachineContext,
    VehiclesEvent,
    VehiclesTypeState
  >(vehiclesMachine, {
    devTools: config.enableDevTools,
    services: {
      sendVehiclesQuery,
      subscribeToVehicleStatus,
    },
    actions: {
      addVehicles: assign<VehiclesMachineContext, VehiclesEvent>({
        vehicleActors: (_context, event: any) =>
          event.data.data.allEquipment.map((vehicle: any) =>
            spawn(
              createVehicleMachine(vehicle.displayName).withConfig(
                {
                  services: {
                    getActionLists: getActionLists(),
                    sendInVehicleAndPersonalStandDown: sendInVehicleAndPersonalStandDown(),
                    sendStandDownPersonal: sendStandDownPersonal(),
                    sendPersonalOwnership: sendPersonalOwnership(),
                    sendReleasePersonalOwnership: sendReleasePersonalOwnership(),
                    sendReleaseVehicle: sendReleaseVehicle(),
                    sendReleasePersonal: sendReleasePersonal(),
                    sendReleaseToContinue: sendReleaseToContinue(),
                    sendIdentify: sendIdentify(),
                    subscribeToControlState: subscribeToControlState(),
                    sendExecuteActionList: sendExecuteActionList() as InvokeCreator<
                      VehicleMachineContext,
                      AnyEventObject,
                      any
                    >,
                    subscribeToMissionState: subscribeToMissionState(),
                  },
                },
                {
                  externalEquipmentReference: vehicle.id,
                  data: vehicle,
                  lastKnownTimestamp: vehicle.status?.timestamp,
                  actionLists: [],
                  missionId: null,
                  commandId: null,
                }
              ),
              `vehicle-${vehicle.externalEquipmentReference}`
            )
          ),
        vehicles: (context, event) =>
          event.type === "done.invoke.sendVehiclesQuery"
            ? event.data.data.allEquipment
            : context.vehicles,
      }),
    },
  });

  useEffect(() => setMounted(true), [setMounted]);

  // Need to check if the machine is mounted due to `useMachine` uses an effect internally

  return mounted ? (
    <VehiclesStateProvider service={service}>{children}</VehiclesStateProvider>
  ) : null;
};

const VehiclesStateProvider: React.FC<IProps> = ({ service, children }) => (
  <VehiclesContext.Provider
    value={{
      vehicleService: service,
    }}
  >
    {children}
  </VehiclesContext.Provider>
);

export default VehiclesProvider;
