import React, { useEffect, useState } from "react";
import { useVehicles, useUiService, useUser } from "@src/hooks";
import { Header } from "@src/containers";
import { Backdrop, CircularProgress } from "@src/components";
import {
  getSiteRelationPromise,
  SiteRelation_queueRelations as queueRelation,
} from "@ats/graphql";

import Zones from "./Zones.container";
import Vehicles from "./Vehicles.container";

export enum QueueRelationType {
  DUMPING_ZONE = "DUMPING_ZONE",
  LOADING_ZONE = "LOADING_ZONE",
}

const ZonesData = () => {
  const [vehiclesState, vehiclesSend] = useVehicles();
  const [uiState, uiSend] = useUiService();
  const { siteId } = uiState.context;
  const { role } = useUser();
  const [zones, setZones] = useState<ReadonlyArray<queueRelation> | null>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (siteId) {
      getSiteRelationPromise({
        areaId: siteId,
      })
        .then((response: any) => {
          setZones(response[0].queueRelations);
          setLoading(false);
        })
        .catch(() => {
          setZones(null);
          setLoading(false);
        });
    }
  }, [siteId]);
  return (
    <>
      {(role === "dumpingOperator" || role === "loadingOperator") && (
        <>
          <Header
            uiState={uiState}
            uiSend={uiSend}
            text={uiState.context.siteName}
            sites={uiState.context.sites ? uiState.context.sites : undefined}
            siteMenu
          />
          <Zones
            uiState={uiState}
            uiSend={uiSend}
            zones={zones}
            role={role}
            loading={loading}
          />

          {loading && (
            <Backdrop open>
              <CircularProgress color="primary" />
            </Backdrop>
          )}
        </>
      )}

      {role === "fieldOperator" && (
        <Vehicles
          vehiclesState={vehiclesState}
          vehiclesSend={vehiclesSend}
          uiState={uiState}
          uiSend={uiSend}
        />
      )}
    </>
  );
};

export default ZonesData;
