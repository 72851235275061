import React, { useState } from "react";
import { Accordion, AccordionSummary, makeStyles } from "@material-ui/core";
import {
  scaniaBlack,
  scaniaLightGray,
  scaniaRed50,
  scaniaRedNegative,
  scaniaWhite,
} from "@src/constants/colors";
import ErrorIcon from "@src/components/icons/Error";
import { UiState, UiEvent } from "@src/machines/ui/ui.machine";
import { getVehicleRef } from "@src/machines/vehicles/vehicles.machine";
import { useVehicles } from "@src/hooks";
import {
  Box,
  Lab,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Typography,
} from "@src/components";
import DegradedEquipment from "@src/containers/Vehicles/DegradedEquipment.component";
import ToastContainer from "@src/containers/Vehicles/Toast.container";
import { ReactComponent as DownArrow } from "@src/assets/icons/down-arrow.svg";
import { ReactComponent as ForwardArrow } from "@src/assets/icons/forward-arrow.svg";
import LockIcon from "@src/containers/Vehicle/icons/LockIcon.container";
import LoadingSkeleton from "@src/components/LoadingSkeleton/LoadingSkeleton";
import VehicleIdentification from "@src/containers/Vehicles/VehicleIdentification";
import { t } from "ttag";
import clsx from "clsx";
import {
  dequeueEquipmentPromise,
  useMergedQueueState,
  useLoadingOperatorLastDequeueList,
  saveOperatorLastDequeue,
} from "@ats/graphql";
import { sortByDisplayName } from "./VehiclesList.component";

type LoadingZoneProps = {
  uiState: UiState;
  uiSend: (event: UiEvent) => void;
  queueId: string;
  paddockId: string;
  siteId: string;
  zoneType: string;
};

export enum IMergedQueueStateStatus {
  NOMINAL = "Nominal",
  DEGRADED = "Degraded",
}

const useStyles = makeStyles(
  {
    root: {
      color: scaniaBlack,
      flexDirection: "column",
      maxWidth: "calc(100% - 20%)",
      height: "calc(100% - 45%)",
      minWidth: "360px",
      margin: "auto",
      overflow: "auto",
    },
    accordion: {
      "&:hover, &:focus": {
        backgroundColor: scaniaWhite,
      },
    },
    vehiclesContainer: {
      color: scaniaBlack,
      flexDirection: "column",
      maxWidth: "calc(100% - 20%)",
      minWidth: "360px",
      margin: "auto",
    },
    header: {
      height: "68px",
      fontSize: "14px",
      lineHeight: "16px",
      display: "flex",
      alignItems: "center",
      paddingLeft: "13px",
      fontWeight: 800,
      color: scaniaBlack,
      letterSpacing: "-0.01rem",
      textTransform: "capitalize",
    },
    label: {
      paddingLeft: "13px",
      fontWeight: 800,
      letterSpacing: "-0.01rem",
      color: scaniaLightGray,
    },
    listButton: {
      borderRadius: "0px",
      left: "2px",
      width: "calc(99%)",
      height: "72px",
      justifyContent: "flex-start",
      color: scaniaBlack,
      fontSize: "16px",
      marginBottom: "6px",
      boxShadow:
        "0px 3px 3px rgba(0, 0, 0, 0.15), 0px -1px 1px rgba(0, 0, 0, 0.1)",
      cursor: "auto",
    },
    callNextButton: {
      borderRadius: "10px",
      height: "56px",
      width: "360px",
      border: "1px solid #000000",
      color: scaniaBlack,
      fontSize: "16px",
      marginBottom: "6px",
      position: "fixed",
      fontWeight: 400,
      bottom: "4px",
      backgroundColor: scaniaWhite,
      "@media only screen and (max-width: 420px)": {
        bottom: "-4px",
      },
    },
    callNextAlert: {
      borderRadius: "10px",
      height: "56px",
      width: "360px",
      fontSize: "16px",
      position: "fixed",
      fontWeight: 400,
      bottom: "65px",
    },
    callNext: {
      display: "flex",
      alignItems: "center",
      color: scaniaBlack,
      flexDirection: "column",
      textAlign: "center",
      width: "inherit",
      bottom: "0px",
      height: "75px",
    },
    avatar: {
      borderRadius: "50%",
      width: "34px",
      height: "34px",
      padding: "10px",
      background: "#5DA7A9",
      textAlign: "center",
      color: "#000000",
      lineHeight: "16px",
    },
    borderTop: {
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      marginBottom: "15px",
    },
    primaryLabel: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      "@media only screen and (max-width: 420px)": {
        width: "215px",
        overflowWrap: "break-word",
      },
    },
    secondaryLabel: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "16px",
      "@media only screen and (max-width: 420px)": {
        width: "215px",
        overflowWrap: "break-word",
      },
    },
    toastContainer: {
      width: "calc(99.5%)",
      marginLeft: "2px",
      background: scaniaRed50,
      height: "88px",
      marginBottom: "16px",
      borderLeft: `4px solid ${scaniaRedNegative}`,
      borderRadius: "4px",
      color: "rgba(13, 15, 19, 0.87)",
      display: "flex",
    },
    toastChild: {
      paddingTop: "15px",
    },
    toastBody: {
      fontWeight: 400,
    },
    toastHeader: {
      fontWeight: 700,
    },
    toastIcon: {
      padding: "17px 17px 0px 17px",
    },
    degradedButton: {
      backgroundColor: scaniaRed50,
      borderLeft: `4px solid ${scaniaRedNegative}`,
      "&:hover, &:focus, &:active": {
        backgroundColor: scaniaRed50,
      },
    },
    secondaryAction: {
      right: "0px",
    },
  },
  { index: 1 }
);

const LoadingZone = ({
  queueId,
  siteId,
  zoneType,
  uiSend,
  uiState,
}: LoadingZoneProps) => {
  const {
    avatar,
    root,
    header,
    accordion,
    listButton,
    callNext,
    callNextButton,
    callNextAlert,
    primaryLabel,
    secondaryLabel,
    label,
    toastContainer,
    toastChild,
    toastBody,
    toastHeader,
    toastIcon,
    degradedButton,
    secondaryAction,
    vehiclesContainer,
  } = useStyles();
  const { zoneName, paddockId } = uiState.context;
  const [vehiclesState] = useVehicles();
  const { vehicles } = vehiclesState.context;
  const [activeCallNext, setActiveCallNext] = useState(true);
  const [callNextFailed, setCallNextFailed] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(false);
  const filteredVehicles = vehicles?.filter(
    (vehicle) => vehicle.areaId === siteId
  );
  const [queueState] = useMergedQueueState({
    areaId: siteId,
    queueId,
  });
  const [lastlyDequeuedList] = useLoadingOperatorLastDequeueList({
    queueId,
  });
  const lastDequeuedEquipment =
    !!lastlyDequeuedList &&
    lastlyDequeuedList.length > 0 &&
    lastlyDequeuedList[0].equipment.length > 0 &&
    lastlyDequeuedList[0].equipment[0];
  const sendCallNextInQueue = () => {
    dequeueEquipmentPromise(queueId)
      .then((result) => {
        saveOperatorLastDequeue({
          areaId: siteId,
          externalEquipmentReference: result.equipmentId,
          queueId,
        });
        setActiveCallNext(true);
        setCallNextFailed(false);
      })
      .catch(() => {
        setActiveCallNext(true);
        setCallNextFailed(true);
      });
  };
  const handleClick = () => {
    setActiveCallNext(false);
    sendCallNextInQueue();
  };
  const handleVehicleClick = (id: string) => {
    uiSend({
      type: "SELECT_VEHICLE",
      id,
      queueId: queueId || "",
      paddockId: paddockId || "",
      zoneName: zoneName || "",
      zoneType: zoneType || "",
    });
  };

  return (
    <>
      {lastDequeuedEquipment && (
        <>
          <List className={vehiclesContainer}>
            <div className={header}>Recently called from queue</div>

            <ListItem
              button
              key={lastDequeuedEquipment.externalEquipmentReference}
              className={listButton}
              onClick={() =>
                handleVehicleClick(
                  lastDequeuedEquipment.externalEquipmentReference
                )
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <div className={avatar} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography className={primaryLabel}>
                    {
                      filteredVehicles?.find(
                        (x) =>
                          x.externalEquipmentReference ===
                          lastDequeuedEquipment.externalEquipmentReference
                      )?.displayName
                    }
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                <ForwardArrow />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </>
      )}
      <List className={vehiclesContainer}>
        <Accordion
          onClick={() => setAccordionOpen(!accordionOpen)}
          className={accordion}
        >
          <AccordionSummary expandIcon={<DownArrow />}>
            <div data-testid="all-vehicles-accordion" className={header}>
              All vehicles
            </div>
          </AccordionSummary>
        </Accordion>
        {accordionOpen && (
          <>
            {!!filteredVehicles &&
              filteredVehicles.length > 0 &&
              sortByDisplayName(filteredVehicles).map((vehicle) => {
                const vehicleRef = getVehicleRef(vehicle.id, vehiclesState);
                return (
                  <ListItem
                    button
                    key={vehicle.id}
                    onClick={() => handleVehicleClick(vehicle.id)}
                    className={listButton}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <div className={avatar} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography className={primaryLabel}>
                          {vehicle.displayName}
                        </Typography>
                      }
                    />
                    <ListItemIcon>
                      {vehicleRef && <LockIcon vehicleRef={vehicleRef} />}
                    </ListItemIcon>
                    <ListItemSecondaryAction>
                      <VehicleIdentification
                        externalEquipmentReference={
                          vehicle.externalEquipmentReference
                        }
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
          </>
        )}
        <ToastContainer />
      </List>
      {queueState === null && (
        <>
          <List className={root}>
            <div className={header}>Queue</div>
            <LoadingSkeleton />
          </List>
        </>
      )}
      {!!queueState && queueState?.length === 0 && (
        <>
          <List className={root}>
            <div className={header}>Queue</div>
            No queue data
          </List>
        </>
      )}
      {!!queueState && queueState.length > 0 && (
        <>
          <List className={root}>
            <div className={header}>{queueState[0]?.displayName}</div>
            {queueState[0]?.status === IMergedQueueStateStatus.DEGRADED && (
              <div className={toastContainer}>
                <div className={clsx(toastIcon, toastChild)}>
                  <ErrorIcon />
                </div>

                <div className={toastChild}>
                  <div>
                    <span className={toastHeader}>Queue degraded!</span>
                  </div>

                  <div>
                    <span className={toastBody}>
                      Remove highlighted vehicle to restore queue
                    </span>
                  </div>
                </div>
              </div>
            )}
            {queueState[0]?.equipment.length === 0 && (
              <div className={label}>No vehicle in the queue</div>
            )}
            {queueState[0]?.equipment.map(
              (
                {
                  displayName,
                  externalEquipmentReference,
                  pos,
                  missionLabel,
                  type,
                },
                index
              ) => (
                <ListItem
                  button
                  key={index}
                  className={
                    type === "EqMoveFailed"
                      ? clsx(listButton, degradedButton)
                      : listButton
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <div className={avatar}>{pos}</div>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography className={primaryLabel}>
                        {displayName}
                      </Typography>
                    }
                    secondary={
                      <Typography className={secondaryLabel}>
                        {missionLabel || ""}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction className={secondaryAction}>
                    {type === "EqMoveFailed" && (
                      <DegradedEquipment
                        externalEquipmentReference={externalEquipmentReference}
                        queueId={queueId}
                      />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              )
            )}
          </List>

          <div className={callNext}>
            {callNextFailed && (
              <Box bottom="100%" width={1} className={callNextAlert}>
                <Lab.Alert severity="error">
                  <Lab.AlertTitle>{t`Unable to call next in queue`}</Lab.AlertTitle>
                </Lab.Alert>
              </Box>
            )}
            <Button
              variant="outlined"
              className={callNextButton}
              onClick={handleClick}
              disabled={
                !activeCallNext ||
                !queueState ||
                (queueState && queueState[0].equipment.length < 1)
              }
              loading={!activeCallNext}
            >
              {t`Call next in queue`}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default LoadingZone;
